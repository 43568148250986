import { Checkbox, FormField } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { FocusEventHandler, MouseEventHandler } from 'react';
import { hasError, ValidationErrorMessage } from '../errors';
import { InfoIcon } from '../../info-icon';

export type ValidatedCheckboxProps = {
    name: string;
    groupName?: string;
    label: string | React.ReactNode;
    testId?: string;
    isMandatory?: boolean;
    onFocus?: FocusEventHandler<HTMLInputElement>;
    disabled?: boolean;
    id?: string;
    hint?: string;
    withHtmlValidationWarning?: boolean;
    className?: string;
    onLinkClick?: MouseEventHandler;
    tooltip?: string | React.ReactNode;
    inlineTooltip?: string | React.ReactNode;
    formLabel?: string;
    isCheckboxMandatory?: boolean;
};

export const ValidatedCheckbox: React.FC<ValidatedCheckboxProps> = ({
    name,
    label,
    testId,
    isMandatory = false,
    onFocus,
    disabled,
    id,
    hint,
    withHtmlValidationWarning,
    className,
    tooltip,
    inlineTooltip,
    formLabel,
    isCheckboxMandatory,
    onLinkClick,
    groupName,
}) => {
    const [field, meta] = useField({ name, type: 'checkbox' });
    const [, groupMeta] = groupName ? useField({ name: groupName }) : [];

    const notion = isCheckboxMandatory ? <span className="c-form-field__notion">* </span> : null;
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const inlineInfoIcon = inlineTooltip ? <InfoIcon text={inlineTooltip} /> : null;
    const errorMessage = (
        <ValidationErrorMessage meta={meta} testId={testId} withHtmlValidationWarning={withHtmlValidationWarning} />
    );

    return (
        <FormField
            type="checkbox"
            testId={`${testId}FormField`}
            notion={isMandatory}
            hint={hint}
            onClick={onLinkClick}
            errorMessage={errorMessage}
            labelText={formLabel}
            labelForId={id}
            infoIcon={infoIcon}
        >
            <Checkbox
                id={id}
                error={groupMeta ? hasError(groupMeta) : hasError(meta)}
                onFocus={onFocus}
                {...field}
                testId={testId}
                disabled={disabled}
                className={className}
            >
                {label}
                {notion}
                {inlineInfoIcon}
            </Checkbox>
        </FormField>
    );
};
