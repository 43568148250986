import React, {
    useState,
    useRef,
    useEffect,
    useCallback,
    RefObject,
    FocusEventHandler,
    MouseEventHandler,
} from 'react';
import { Fieldset, Button, Card } from '@vwfs-bronson/bronson-react';
import { ValidatedCheckbox } from '../validation-inputs/validated-checkbox';
import { InfoIcon } from '../info-icon';

export const InfoCheckbox: React.FC<{
    infoLinkHref?: string;
    preLinkLabel?: string;
    linkLabel: string;
    afterLinkLabel?: string;
    fieldName: string;
    testId?: string;
    testIdInfoText?: string;
    tooltip?: string;
    linkRef?: RefObject<HTMLAnchorElement>;
    isLinkButton?: boolean;
    id?: string;
    onFocus?: FocusEventHandler<HTMLInputElement>;
}> = ({
    preLinkLabel,
    linkLabel,
    afterLinkLabel = '.',
    testId,
    fieldName,
    testIdInfoText,
    infoLinkHref,
    tooltip,
    linkRef,
    isLinkButton,
    children,
    id,
    onFocus,
}) => {
    const [showInfoText, setShowInfoText] = useState<boolean>(false);
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;
    const cardRef = useRef<HTMLDivElement>(null);

    function myAfterLink() {
        if (afterLinkLabel === '.') {
            return afterLinkLabel;
        }
        return ' ' + afterLinkLabel;
    }

    const scrollToCard = useCallback(() => {
        cardRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }, []);

    const handleExternalClick = (): void => {
        setShowInfoText(true);
        scrollToCard();
    };

    useEffect(() => {
        linkRef?.current?.addEventListener('click', handleExternalClick);
        return (): void => linkRef?.current?.removeEventListener('click', handleExternalClick);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
        event.preventDefault();
    };

    return (
        <>
            <Fieldset.Row>
                <ValidatedCheckbox
                    id={id}
                    label={
                        <>
                            {preLinkLabel}
                            {preLinkLabel ? ' ' : undefined}
                            {children &&
                                (isLinkButton ? (
                                    <a
                                        onClick={(e): void => {
                                            e.preventDefault();
                                            setShowInfoText(!showInfoText);
                                        }}
                                    >
                                        {linkLabel}
                                    </a>
                                ) : (
                                    <Button onClick={handleClick} link={true} testId={testId + 'ExpandableLink'}>
                                        {linkLabel}
                                    </Button>
                                ))}
                            {infoLinkHref && (
                                <a
                                    href={infoLinkHref}
                                    target={'_blank'}
                                    rel="noreferrer"
                                    data-testid={testId + 'ExternalLink'}
                                >
                                    {linkLabel}
                                </a>
                            )}
                            {myAfterLink()}
                            {infoIcon}
                        </>
                    }
                    testId={testId}
                    name={fieldName}
                    onFocus={onFocus}
                />
            </Fieldset.Row>
            {showInfoText && (
                <Fieldset.Row testId={testIdInfoText}>
                    <Card element={'article'} scrollable={true}>
                        <div ref={cardRef} className={'u-text-left'}>
                            {children}
                        </div>
                    </Card>
                </Fieldset.Row>
            )}
        </>
    );
};
